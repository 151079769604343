<template>
    <div class="login-container-wrapper">
        <s-form role="form" @submit="onSubmit" class="login-container bs-5">

            <h2>Register</h2>
            <small>New user</small>
            <div class="row">
                <div class="col-md-12">
                    <validated-input name="Name" label="Name" v-model="model.name" :rules="rules.name"/>
                </div>
                <div class="col-md-6">
                    <validated-input name="Username" label="Username" v-model="model.username" :rules="rules.username"/>
                </div>
                <div class="col-md-6">
                    <validated-input label="Password" type="password" v-model="model.password" v-if="!model.id" :rules="rules.password"/>
                </div>
                <div class="col-md-12">
                    <validated-input name="Email" type="email" label="Email" v-model="model.email" :rules="rules.email"/>
                </div>
                <div class="col-md-6">
                    <validated-mask-input mask="##########" name="Phone" label="Phone Number" v-model="model.phone" :rules="rules.phone"/>
                </div>
                <div class="col-md-6">
                    <validated-mask-input mask="##########" name="Whatsapp Number" label="Whatsapp Number" v-model="model.whatsapp_number" :rules="rules.whatsapp_number"/>
                </div>

                <div class="col-md-12">
                    <btn color="primary" text="Create"/>
                </div>

            </div>
        </s-form>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    name: 'NewUserRegistration',
    components: {},
    data () {
        return {
            model: {
                username: '',
                password: '',
                email: '',
                phone: '',
                whatsapp_number: '',
                name: '',
                role: '3'
            },
            rules: {
                username: {
                    required: true
                },
                password: {
                    required: true
                },
                email: {
                    required: true
                },
                phone: {
                    required: true
                },
                whatsapp_number: {
                    required: false
                },
                name: {
                    required: true
                },
                role: {
                    required: true
                }
            }
        };
    },
    methods: {
        async onSubmit () {
            const url = urls.users.create;
            const response = await axios.form(url, this.model);
            if (response) {
                this.$router.push({ path: '/login/' });
            }
        }
    }
};
</script>

<style scoped>

</style>
